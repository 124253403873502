import ProfileDetailsForm from "../onboarding/ProfileDetailsForm";
import ProfileInfoDisplay from "./ProfileInfoDisplay";


const Profile: React.FC = () => {

    return (<>
        <div className="page-container bg-offwhite">
            <ProfileInfoDisplay />
            {/* <ProfileDetailsForm onNextStep={() => { }} /> */}
        </div>
    </>);
}

export default Profile;