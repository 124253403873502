import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export enum TOAST_TYPE {
    SUCCESS,
    ERROR,
    INFO
}

export interface ToastUtilParams {
    status: TOAST_TYPE,
    message?: string
    durationInMS?: number
}

export const showToastUtil = (params: ToastUtilParams) => {
    const { status, message, durationInMS = 7000 } = params;
    switch (status) {
        case TOAST_TYPE.SUCCESS:
            const successMsg = message ?? 'Success!'
            toast.success(successMsg, {
                position: 'top-right',
                autoClose: durationInMS,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            break;
        case TOAST_TYPE.INFO:
            const infoMsg = message ?? 'Neotrust!'
            toast.info(infoMsg, {
                position: 'top-right',
                autoClose: durationInMS,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            break;
        case TOAST_TYPE.ERROR:
        default:
            let errorMsg = message ?? 'Sorry! Encountered an error.\nPlease try again after sometime.'
            toast.error(errorMsg, {
                position: 'top-right',
                autoClose: durationInMS,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            break;
    }
}
