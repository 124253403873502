import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
}


const PaymentCancelled: React.FC<IProps> = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/onboarding?step=plan');
    }, []);
    return null;
}

export default PaymentCancelled;
