import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../assets/v2/logo/logo.svg';
import IconCall from '../../assets/v2/icons/icon-call.svg';
import IconHome from '../../assets/v2/icon-home.svg';
import IconLogout from '../../assets/v2/icon-logout.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const navigation_old = [
    { name: 'Why Neotrust?', link: '/', scrollLink: 'neotrust' },
    { name: 'Company', link: '/', scrollLink: 'neoteam' },
    { name: 'Solutions', link: '/solutions', scrollLink: '' },
    { name: 'Plans', link: '/plans', scrollLink: '' },
    { name: 'Profile', link: '/profile', scrollLink: '' },
]
const navigation = [
    { name: 'Trust', link: '/trust', scrollLink: '' },
    { name: 'Plans', link: '/plans', scrollLink: '' },
    { name: 'Industries', link: '/industires', scrollLink: '' },
    { name: 'Company', link: '/company', scrollLink: '' },
    // { name: 'Profile', link: '/profile', scrollLink: '' },
]

type AppHeaderProps = {
    classNameProps?: string | null;
};

const AppHeader: React.FC<AppHeaderProps> = ({ classNameProps }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { logout, loginWithRedirect, isAuthenticated } = useAuth0();

    const handleNavigation = (path: string, scrollTarget: string) => {
        if (location.pathname === path) {
            // If we're already on the target page, just scroll and update the hash in the URL
            if (scrollTarget) {
                setTimeout(() => {
                    const element = document.getElementById(scrollTarget);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        window.history.pushState(null, '', `#${scrollTarget}`);
                    }
                }, 100);
            }
        } else {
            // Navigate to the target page
            navigate(path);

            // After navigation, scroll to the target (if specified) and update the hash in the URL
            if (scrollTarget) {
                setTimeout(() => {
                    const element = document.getElementById(scrollTarget);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        window.history.pushState(null, '', `#${scrollTarget}`);
                    }
                }, 100);
            }
        }
    };


    return (
        <header id='app-header' className={classNameProps || "bg-white"}>
            <nav className="mx-auto flex container items-center justify-between py-6 lg:px-8 sm:px-6 px-4" aria-label="Global">
                <Link to="/" className="">
                    <span className="sr-only">Neotrust</span>
                    <img className="w-60 h-auto" src={Logo} alt="Neotrust" />
                </Link>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="pr-5 inline-flex items-center justify-center rounded-md text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-8 items-center">
                    <Link to="/" className="">
                        <img className="w-5 h-auto" src={IconHome} alt="Home" />
                    </Link>
                    {navigation.map((item) => {
                        // if (isAuthenticated && item.name === 'Plans') return null;
                        // if (!isAuthenticated && item.name === 'Profile') return null;
                        return (
                            <Link key={item.name}
                                to={item.link}
                                onClick={() => handleNavigation(item.link, item.scrollLink)}
                                className="text-xl font-bold leading-6 text-grsay-900 hover:text-gray-600 cursor-pointer"
                            >
                                {item.name}
                            </Link>
                        )
                    })}
                </div>
                <div className="hidden lg:flex lg:gap-x-12 items-center">
                    {isAuthenticated ? <>
                        <button onClick={() => { navigate('/dashboard') }} className="min-w-[120px] ibtn ibtn-primary px-6">
                            Dashboard
                        </button>
                        <div onClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} className="cursor-pointer">
                            <img className="w-5 h-auto" src={IconLogout} alt="Logout" />
                        </div>

                    </> : <>
                        <Link to="/contact" className="">
                            <img className="w-5 h-auto" src={IconCall} alt="Contact" />
                        </Link>
                        <button onClick={() => { loginWithRedirect() }} className="min-w-[120px] ibtn ibtn-primary px-6">
                            {/* Login <span aria-hidden="true">&rarr;</span> */}
                            Login
                        </button>
                    </>}
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className=""
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Neotrust</span>
                            <img
                                className="w-60 h-auto"
                                src={Logo}
                                alt="Neotrust"
                            />
                        </Link>
                        <button
                            type="button"
                            className="rounded-md text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => {
                                    // if (isAuthenticated && item.name === 'Plans') return null;
                                    if (item.name === 'Profile') return null;
                                    return (
                                        <Link
                                            key={item.name}
                                            to={item.link}
                                            onClick={() => {
                                                setMobileMenuOpen(false);
                                                handleNavigation(item.link, item.scrollLink);
                                            }}
                                            className="-mx-3 px-3 block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </Link>
                                    )
                                })}
                                {isAuthenticated &&
                                    <div
                                        onClick={() => {
                                            setMobileMenuOpen(false);
                                            navigate('/dashboard');
                                        }}
                                        className="text-base font-semibold leading-6 block rounded-lg -mx-3 px-3 py-2.5 text-gray-900 hover:bg-gray-50 cursor-pointer"
                                    >Dashboard</div>
                                }
                            </div>
                            <div className="py-6">
                                {isAuthenticated ? <>
                                    <div
                                        onClick={() => {
                                            setMobileMenuOpen(false);
                                            navigate('/profile');
                                        }}
                                        className="text-base font-semibold leading-6 block rounded-lg -mx-3 px-3 py-2.5 text-gray-900 hover:bg-gray-50 cursor-pointer"
                                    >Profile</div>
                                </> : <>
                                    <div onClick={() => { loginWithRedirect() }} className="text-base font-semibold leading-6 -mx-3 px-3 py-2 block rounded-lg text-gray-900 hover:bg-gray-50 cursor-pointer">
                                        Login <span aria-hidden="true">&rarr;</span>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default AppHeader;
