import React from 'react';
import LogoIcon from '../../assets/v2/logo/logo-icon.svg';

const AboutSection: React.FC = () => {
    return (
        <section className='bg-white'>
            <div className='container mx-auto flex flex-col sm:flex-row items-center p-8 text-i-indigo'>
                <div className='flex justify-center mb-6 sm:mb-0'>
                    <img className='h-auto max-h-64 w-auto min-w-[200px]' src={LogoIcon} alt='Neotrust' />
                </div>
                <div className='text-center sm:text-left pl-0 sm:pl-8 lg:pl-12'>
                    <div className='text-2xl leading-8'>
                        {'At Neotrust, our mission is simple: to create a secure, transparent, and trusted digital world. As the digital economy continues to grow, the need for immutable and verifiable data becomes essential. We are committed to delivering solutions that protect the integrity of digital files while ensuring they remain easily accessible and verifiable for future use.'}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
