import React from 'react';
import Image1 from '../../assets/v2/images/ind-creative.svg';
import Image2 from '../../assets/v2/images/ind-tech.svg';
import Image3 from '../../assets/v2/images/ind-biz.svg';
import Image4 from '../../assets/v2/images/ind-supply.svg';
import Image5 from '../../assets/v2/images/ind-med.svg';
import Image6 from '../../assets/v2/images/ind-every.svg';

const TrustIndustriesSection: React.FC = () => {
    const trustItems = [
        {
            title: 'Creators & Artists',
            description: 'Protect your work from unauthorized use and theft with proven ownership and creation dates for your digital content.',
            image: Image1
        },
        {
            title: 'Tech & Innovation',
            description: 'Safeguard your intellectual property, from software to patents, ensuring that every idea and prototype has verified ownership from inception.',
            image: Image2
        },
        {
            title: 'Legal & Financial',
            description: 'Manage sensitive documents confidently, with tamper-proof storage for contracts, legal filings, and financial records that remain unaltered and verifiable.',
            image: Image3
        },
        {
            title: 'Supply Chain & Manufacturing',
            description: 'Track and verify inventory with secure, traceable records of ownership and transactions, protecting your business from fraud and ensuring transparency.',
            image: Image4
        },
        {
            title: 'Medical Sector',
            description: 'Protect sensitive patient data and medical records with secure, immutable timestamps and verified ownership, ensuring compliance and enhancing trust in healthcare services.',
            image: Image5
        },
        {
            title: 'Everyone',
            description: 'Neotrust is designed for anyone looking to secure their digital assets, providing peace of mind and trust in an increasingly digital world.',
            image: Image6
        }
    ];

    return (
        <div className='relative flex bg-white'>
            <div className='container mx-auto text-center py-12 lg:px-12 md:px-8 px-4'>
                <div className="text-4xl font-archivo text-i-indigo">{'Who Needs Trust?'}</div>
                <div className="text-2xl text-i-indigo-dark py-6">{'No matter the industry, Neotrust is the new standard for securing and verifying digital assets.'}</div>

                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
                    {trustItems.map((item, index) => (
                        <div key={index} className='flex flex-col items-center text-center'>
                            <img src={item.image} alt={item.title} className='w-auto h-40 mb-4 rounded-full' />
                            <div className='text-xl font-semibold text-i-indigo mb-2'>{item.title}</div>
                            <div className='text-i-indigo-dark'>{item.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TrustIndustriesSection;
