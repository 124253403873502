import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Stamp from '../stamp/Stamp';
import DashboardPage from './DashboardPage';
import Verify from '../verify/Verify';

const DashboardRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="overview" replace />} />
            <Route path="portfolio" element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<DashboardPage />} />
            <Route path="stamp" element={<Stamp />} />
            <Route path="verify" element={<Verify />} />

            {/* Catch-all for any unmatched nested routes */}
            <Route path="*" element={<Navigate to="overview" replace />} />
        </Routes>
    );
};

export default DashboardRouter;
