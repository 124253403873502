import React from 'react';
import HeroSection from './HeroSection';
import PricingPlansSection from './PricingPlansSection';
import WhyNeotrustSection from '../../components/common/WhyNeotrustSection';
import ComparisonTableSection from './ComparisonTableSection';
import CTASection from './CTASection';

const PlansPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeroSection />
            <PricingPlansSection />
            <WhyNeotrustSection />
            <ComparisonTableSection />
            <CTASection />
        </div>
    );
};

export default PlansPage;
