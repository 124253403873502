import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { EnvConfig } from "../../utils/envConfig";

interface IProps {
}

const CheckoutPage: React.FC<IProps> = () => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                setToken(accessToken);
                // console.log(accessToken);
            } catch (e: any) {
                console.log(e.message);
            }
        };
        getAccessToken();
    }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    useEffect(() => {
        const handleSuccess = async () => {
            try {
                const response = await fetch(`${EnvConfig.API_DOMAIN}/payment/success`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ session_id: sessionId }),
                });
                const data = await response.json();
                console.log(data);
            } catch (e: any) {
                console.log(e.message);
            }
        };
        token && handleSuccess();
    }, [token]);

    return (
        <div className="bg-white shadow sm:rounded-lg flex flex-col items-center max-w-7xl mx-auto my-6 p-4">
            <>
                <h3 className="text-base font-semibold leading-6 text-bluegray-900">You Are All Set!</h3>
                <p className="mt-2 text-gray-800">You can now get started and access your dashboard.</p>
                <button
                    type="button"
                    className="mt-6 inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600"
                    onClick={() => {
                        // Implement your logic to go to the dashboard here
                        // console.log("Go to Dashboard!");
                        navigate('/');
                    }}
                >
                    Go to Home
                </button>
            </>
        </div>
    );
};

export default CheckoutPage;
