import { Auth0ContextInterface } from '@auth0/auth0-react';

class AuthManager {
    private static instance: AuthManager;
    private token: string;
    private tokenExpiry: number;
    private auth0: Auth0ContextInterface;

    private constructor(auth0: Auth0ContextInterface) {
        this.auth0 = auth0;
        this.token = '';
        this.tokenExpiry = 0;
    }

    public static getInstance(auth0: Auth0ContextInterface): AuthManager {
        if (!AuthManager.instance) {
            AuthManager.instance = new AuthManager(auth0);
        }
        return AuthManager.instance;
    }

    public async getAccessToken(): Promise<string> {
        if (!this.token || Date.now() >= this.tokenExpiry) {
            try {
                const accessToken = await this.auth0.getAccessTokenSilently();
                // Set the token expiry to one hour from now (adjust as needed)
                this.tokenExpiry = Date.now() + 3600 * 1000;
                this.token = accessToken;
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        }
        return this.token;
    }
}

export default AuthManager;
