import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const CTASection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <section className="bg-i-indigo">
            <div className="container mx-auto p-8 text-center text-white">
                <div className="text-3xl font-archivo mb-4">Ready to Secure Your Files?</div>
                <div className="text-lg mb-8">Sign up now and start protecting your digital assets.</div>
                <button
                    className="ibtn ibtn-secondary-alt py-3 px-8"
                    onClick={() => loginWithRedirect()}
                >
                    Sign Up Now
                </button>
            </div>
        </section>
    );
};

export default CTASection;
