import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import BgHomeCTA from '../../assets/v2/containers/bg-home-cta.svg';
import IconProtect from '../../assets/v2/icon-protect.svg';
import IconClock from '../../assets/v2/icon-clock.svg';
import IconProfile from '../../assets/v2/icon-profile.svg';
import BackgroundContainer from '../../components/common/BackgroundContainer';

const CTASection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    const aspectRatioPercentage = (777.85 / 1000) * 100; // 77.785%

    return (
        <BackgroundContainer bgImage={BgHomeCTA} aspectRatio='777.85 / 1000' maxHeight='1500px' className="bg-i-indigo">

            <div className='container mx-auto flex flex-wrap items-center py-12 lg:px-8 sm:px-6 px-4'>
                <div className='w-full flex flex-row'>
                    {/* <div className='w-1/2 flex justify-start'></div> */}
                    <div className='sm:pl-[50%] pl-[45%] text-white flex flex-col justify-around'>
                        <h2 className='xl:text-6xl lg:text-5xl sm:text-3xl text-2xl font-archivo'>Never worry about file ownership again</h2>
                        <ul className='lg:text-3xl sm:text-2xl text-base'>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconProtect} alt='' />
                                <span className='lg:pl-12 sm:pl-8 pl-4'>Protect your IP for its entire lifecycle</span>
                            </li>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconClock} alt='' />
                                <span className='lg:pl-12 sm:pl-8 pl-4'>Securely authenticate your work and timestamp with a single click</span>
                            </li>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconProfile} alt='' />
                                <span className='lg:pl-12 sm:pl-8 pl-4'>Tie your work to your identity, and clarify and certify ownership</span>
                            </li>
                        </ul>

                        <div className='flex flex-wrap items-center justify-evenly py-6'>
                            <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-3 px-6 lg:text-3xl sm:text-2xl text-xl'>
                                Sign Up Now
                            </button>
                            <Link to='/plans' className='text-i-mintleaf underline hover:text-white lg:text-3xl sm:text-2xl text-xl'>
                                View Pricing
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundContainer>
    );
};

export default CTASection;
