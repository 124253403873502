import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Image1 from '../../assets/v2/images/industry-supply.svg';
import Image2 from '../../assets/v2/images/industry-law.svg';
import Image3 from '../../assets/v2/images/industry-medical.svg';
import Image4 from '../../assets/v2/images/industry-creative.svg';

const IndustriesSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    const imageSources = [Image1, Image2, Image3, Image4];
    return (
        <div className='bg-white'>
            <div className='container mx-auto text-center py-12 lg:px-8 sm:px-6 px-4'>
                <div className='lg:text-5xl sm:text-4xl text-3xl font-archivo text-i-indigo'>Who is Neotrust for?</div>
                <div className='lg:text-3xl sm:text-2xl text-xl text-i-indigo-dark py-8'>Neotrust is for everyone across all industries looking to secure their digital files, work, portfolio and digital records.</div>

                <div className='flex flex-col md:flex-row justify-center'>
                    {imageSources.map((imageSrc, index) => (
                        <div key={index} className='w-full md:w-1/4 flex justify-center'>
                            <img className={`w-full h-auto rounded-lg sm:-mb-12 -mb-6 
                                ${index === 1 ? '-mt-4' : ''}
                                ${index === 1 || index === 3 ? 'hidden md:flex' : 'flex'} 
                            `}
                                src={imageSrc} alt={`Image ${index + 1}`}
                            />
                        </div>
                    ))}
                </div>

                <div className='lg:text-2xl sm:text-xl text-lg text-i-gray-dark mt-8 px-4'>
                    Authenticate any digital file with verifiable ownership. The digital trust solution for professional and personal use.
                </div>

                <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-primary mt-4 py-3 px-6 lg:text-3xl sm:text-2xl text-xl'>
                    Secure Your Files
                </button>
            </div>
        </div>
    );
};

export default IndustriesSection;