import React from 'react';
import TransactionsTable from './TransactionsTable';
import StampComponent from '../stamp/Stamp';
import UsageCharts from './UsageCharts';

const DashboardPage: React.FC = () => {
    return (
        <>
            <div className="w-full flex flex-col md:flex-row items-stretch justify-center bg-i-gray">
                <UsageCharts classes='rounded-xl md:rounded-l-none md:rounded-r-xl m-3 md:mx-0'/>
                <StampComponent />
            </div>
            <TransactionsTable />
        </>
    );
};

export default DashboardPage;
