import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import API from "../../apiClient/NeoTrustAPI";
import { UserInfoAPIResponse, UserStatus, checkValidUserPlan } from "../../utils/userUtils";
import { useNavigate } from "react-router-dom";
import { STATUS } from "../../utils/common";

const LoginChecker = () => {
    const { isAuthenticated } = useAuth0();
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
    const navigate = useNavigate();

    const [loader, setLoader] = useState<STATUS>(STATUS.NONE);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const [response, verificationResponse] = await Promise.all([
                    api.get('/user/info'),
                    api.get('/email-verification-status')
                ]);
                // const response = await api.get('/user/info');
                if (verificationResponse.status !== 200) throw new Error('Error fetching email verification status.');
                const isVerified: boolean = verificationResponse.data?.email_verified;
                if (!isVerified) {
                    setLoader(STATUS.ERROR);
                    return;
                }
                if (response.status !== 200) throw new Error('Error fetching user info.')
                const data: UserInfoAPIResponse = response.data;
                const { userStatus } = checkValidUserPlan(data);
                if (userStatus === UserStatus.EXPIRED) {
                    navigate('/onboarding?step=plan');
                    return;
                } else if (userStatus === UserStatus.KEY_READY) {
                    navigate('/onboarding?step=plan');
                    return;
                } else if (userStatus === UserStatus.NO_USER) {
                    navigate('/onboarding');
                    return;
                } else if (userStatus === UserStatus.COMPLETE) {
                    navigate('/');
                    return;
                }
            } catch (e: any) {
                console.error('Error fetching user info:', e);
                navigate('/');
                // showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error fetching user info.' });
            }
        };
        if (isAuthenticated) {
            getUserInfo();
        }
    }, [isAuthenticated]);


    return (
        <div className="page-container bg-offwhite dark justify-center items-center">
            {loader === STATUS.ERROR ? <>
                <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                    <h2 className="text-xl font-bold mb-2">Email Verification Needed</h2>
                    <p>Please verify your email before continuing. If you've already verified, you might need to refresh the page to update your status.</p>
                    <button onClick={() => {window.location.reload()}} className="mt-5 inline-block text-base font-bold leading-6 py-3 px-8 bg-ntpurple hover:bg-ntpurple-600 hover:border-ntpurple-600 text-white border-ntpurple border-2 transition ease-in duration-200 text-center shadow-md focus:outline-none rounded-full hover:bg-white hover:text-[#8965e9]">Reload</button>
                </div>
            </> :
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-ntpurple"></div>
            }
        </div>
    );
};

export default LoginChecker;