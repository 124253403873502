import React from 'react';
import { Link } from 'react-router-dom';
import Asset3 from '../../assets/v2/containers/bg-asset-cta.svg';

const WhyNeotrustSection: React.FC = () => {
    return (
        <section className='relative flex bg-i-indigo'>
            <div className='absolute inset-0 bg-no-repeat bg-cover bg-center z-0' style={{ backgroundImage: `url(${Asset3})` }} ></div>
            <div className='container mx-auto flex flex-col text-white p-8 z-10'>
                <div className='text-3xl py-2 font-archivo'>Why Neotrust?</div>
                <div className='text-xl py-4 inline-flex lg:max-w-[80%]'>
                    We’re the only platform that offers identity-linked and time-stamped ownership of any digital file. Our platform enhances security, compliance, and trust in all digital assets.
                </div>
                <Link to='/trust' className='text-lg py-2 inline-flex font-archivo text-i-mintleaf underline'>
                    Learn more about digital trust.
                </Link>
            </div>
        </section>
    );
};

export default WhyNeotrustSection;
