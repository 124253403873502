import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="flex flex-col items-start px-4 py-6 md:px-10 md:py-12 max-w-7xl mx-auto space-y-4">
            <h1 className="text-3xl font-bold">Privacy Policy</h1>
            <p>Last Updated: August 24,2023</p>
            <h2 className="text-xl font-semibold">1. Introduction</h2>
            <p>Thank you for choosing Consentize and our innovative product, Neotrust. Your trust is our top priority, and we are dedicated to safeguarding your personal data. This Privacy Policy outlines our commitment to maintaining your privacy. Should you have any inquiries or require clarification on our data practices, please reach out to us at Hello@Consentize.com.</p>
            <h2 className="text-xl font-semibold">2. Scope of Data Collection</h2>
            <h2 className="text-xl font-semibold">2.1. Voluntary Data Submission</h2>
            <p>When you engage with Neotrust, whether by registering, seeking information about our offerings, or reaching out for support, you may provide us with certain personal data.</p>
            <h2 className="text-xl font-semibold">2.2. Types of Data We Collect</h2>
            <p><span className='font-semibold'><span className="pl-5 pr-2">-</span>Identity & Contact Information:</span> This encompasses your full name, email, postal address, phone number, and other related data you might share.</p>
            <p><span className='font-semibold'><span className="pl-5 pr-2">-</span>Authentication Details:</span> Information like passwords, security questions, and other relevant data to ensure secure access to our platform.</p>
            <h2 className="text-xl font-semibold">3. Purpose & Use of Collected Data</h2>
            <p className=""><span className="pl-5 pr-2">-</span>3.1. To streamline the account creation and login procedure.</p>
            <p className=""><span className="pl-5 pr-2">-</span>3.2. To keep you updated with our latest offerings, promotions, and news.</p>
            <p className=""><span className="pl-5 pr-2">-</span>3.3. To efficiently process and manage your orders or requests.</p>
            <p className=""><span className="pl-5 pr-2">-</span>3.4. To enhance our services through data analysis, understanding user preferences, and refining Neotrust.</p>
            <h2 className="text-xl font-semibold">4. Data Sharing & Disclosure</h2>
            <h2 className="text-xl font-semibold">4.1. We value your privacy and only share your data under specific circumstances:</h2>
            <p className=""><span className="pl-5 pr-2">-</span>With your explicit consent.</p>
            <p className=""><span className="pl-5 pr-2">-</span>To adhere to legal and regulatory mandates.</p>
            <p className=""><span className="pl-5 pr-2">-</span>To deliver the services you've requested.</p>
            <p className=""><span className="pl-5 pr-2">-</span>To safeguard our rights or property.</p>
            <p className=""><span className="pl-5 pr-2">-</span>In line with our business commitments and contracts.</p>
            <h2 className="text-xl font-semibold">5. Ensuring Data Security</h2>
            <p className="">5.1. We employ a combination of organizational protocols and advanced technical solutions to ensure the utmost protection of your data against unauthorized access, loss, or misuse.</p>
            <h2 className="text-xl font-semibold">6. Cookies & Tracking Mechanisms</h2>
            <p className="">6.1. To enhance user experience and gather insights into user behavior, we utilize cookies and similar technologies. This aids us in offering a more personalized experience and understanding how users navigate Neotrust.</p>
            <h2 className="text-xl font-semibold">7. Your Rights & Contacting Us</h2>
            <p className="">7.1. You have specific rights concerning your personal data, including accessing, modifying, or requesting deletion. If you wish to exercise these rights or have queries about this policy, please connect with our Support Department at Hello@consentize.com</p>
            <h2 className="text-xl font-semibold"></h2>
            <p className="">7.2. Alternatively, you can write to us at:</p>
            <div className="">Consentize Inc</div>
            <div className="!m-0">701 Ellicott Street</div>
            <div className="!m-0">BLD# 266</div>
            <div className="!m-0">Buffalo, NY 14203</div>
        </div>
    );
};

export default PrivacyPolicy;

