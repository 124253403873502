import React from 'react';
import BgHomeCTA from '../assets/v2/containers/bg-home-hero.svg';

const Temp: React.FC = () => {
    return (<>
        <section className='bg-white'>
            <div className='container mx-auto p-8 text-center text-i-indigo'>
                <div className='text-4xl font-archivo'>
                    Digital Trust Plans that fit your needs.
                </div>
                <div className='text-xl text-i-indigo-dark mt-2'>
                    Neotrust provides security and verification of your files so you never need to worry again.
                </div>
            </div>
        </section>
        <div className='relative flex '>
            <img src={BgHomeCTA} className='inline-flex h-[100px]' alt='placeholder' />
        </div>
    </>
    );
};

export default Temp;
