import axios, { AxiosInstance } from 'axios';
import AuthManager from '../auth/AuthManager';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { EnvConfig } from '../utils/envConfig';

class API {
    private static instance: API;
    private axiosInstance: AxiosInstance;
    private authManager: AuthManager;

    private constructor(auth0: Auth0ContextInterface) {
        this.authManager = AuthManager.getInstance(auth0);

        this.axiosInstance = axios.create({
            baseURL: EnvConfig.API_DOMAIN
        });

        // Add an interceptor to inject the access token into each request's headers
        this.axiosInstance.interceptors.request.use(async (config) => {
            const token = await this.authManager.getAccessToken();
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    }

    public static getInstance(auth0: Auth0ContextInterface): API {
        if (!API.instance) {
            API.instance = new API(auth0);
        }
        return API.instance;
    }

    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }
}

export default API;
