export enum STATUS {
    NONE = 'NONE',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export const handleErrorMsg = (error: any, defaultMsg?: string): string => {
    defaultMsg = defaultMsg || 'An error occurred.';
    if (error.isAxiosError) {
        if (error.response) {
            const status = error.response.status;

            if (status >= 400 && status < 500) {
                return error.response.data?.message || defaultMsg;
            } else if (status >= 500) {
                return 'Sorry, we encountered an error on our server.';
            }
        }
    } else if (error instanceof Error) {
        return error.message;
    }

    return defaultMsg;
};

export const formatFileSize = (bytes: number): string => {
    if (isNaN(bytes) || bytes <= 0) {
        return '-';
    }
    if (bytes < 1024) {
        return bytes + " B";
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
}

export const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust options as needed
}

export const formatISODate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
    return date.toLocaleString(); // Adjust options as needed
}
