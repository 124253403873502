import React from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import TrustedSection from './TrustedSection';
import TimelineSection from './TimelineSection';
import WhyNeotrustSection from '../../components/common/WhyNeotrustSection';

const CompanyPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeroSection />
            <AboutSection />
            <TrustedSection />
            <TimelineSection />
            <WhyNeotrustSection />
        </div>
    );
};

export default CompanyPage;
