import React from 'react';

const TermsAndConditions: React.FC = () => {
    return (
        <div className="flex flex-col items-start px-4 py-6 md:px-10 md:py-12 max-w-7xl mx-auto space-y-4">
            <h1 className="text-3xl font-bold">Terms and Conditions</h1>
            <p>Last Updated: August 24,2023</p>
            <h2 className="text-xl font-semibold">1. Introduction</h2>
            <p>Welcome to Consentize and our product, Neotrust. By accessing and using our services, you agree to abide by the following terms and conditions. If you disagree with any part of these terms, please refrain from using our services.</p>
            <h2 className="text-xl font-semibold">2. User Registration</h2>
            <p>To access certain features of Neotrust, users may be required to register for an account. Users must provide accurate and complete information and keep their account details up-to-date.</p>
            <h2 className="text-xl font-semibold"></h2>
            <h2 className="text-xl font-semibold">3. Use of the Service</h2>
            <p>Users agree to use Neotrust responsibly and not to:</p>
            <p><span className="pl-5 pr-2">-</span>Engage in any unlawful activities.</p>
            <p><span className="pl-5 pr-2">-</span>Attempt to breach our security or authentication measures.</p>
            <p><span className="pl-5 pr-2">-</span>Reproduce, duplicate, or copy any part of our service without explicit permission.</p>
            <h2 className="text-xl font-semibold">4. Intellectual Property</h2>
            <p>All content, designs, graphics, and other materials related to Neotrust are the exclusive property of Consentize and are protected by copyright and intellectual property laws.</p>
            <h2 className="text-xl font-semibold">5. Termination and Refunds</h2>
            <p>We reserve the right to suspend or terminate user accounts and access to Neotrust for violations of these terms or any other misuse. If Neotrust operates on a subscription model, users will be informed of any automatic renewals, and refunds will be issued in compliance with New York's consumer protection laws.</p>
            <h2 className="text-xl font-semibold">6. Limitation of Liability</h2>
            <p>While we strive to offer impeccable service, Consentize and Neotrust will not be liable for any indirect, incidental, or consequential damages arising out of the use or inability to use our services, except where liability arises from gross negligence or intentional misconduct.</p>
            <h2 className="text-xl font-semibold">7. Dispute Resolution</h2>
            <p>Any disputes arising from these terms will be resolved through binding arbitration in New York, NY, unless such a clause is prohibited by New York law.</p>
            <h2 className="text-xl font-semibold">8. Governing Law</h2>
            <p>These terms and conditions are governed by and construed in accordance with the laws of the state of New York.</p>
            <h2 className="text-xl font-semibold">9. Data Protection and Privacy</h2>
            <p>We are committed to protecting your data. In the event of a data breach, we will notify affected New York residents in compliance with New York's SHIELD Act.</p>
            <h2 className="text-xl font-semibold">10. Children's Data</h2>
            <p>We do not knowingly collect data from minors. If we become aware that a minor has provided us with personal data without parental consent, we will take steps to remove such data and terminate the minor's account.</p>
            <h2 className="text-xl font-semibold">11. Changes to These Terms</h2>
            <p>We may periodically update these terms and conditions. Users will be notified of any significant changes and are expected to review and stay informed about the terms.</p>
            <h2 className="text-xl font-semibold">12. Contact Us</h2>
            <div><span className="pl-5 pr-2">-</span>Email: <span className='font-semibold'>Hello@Consentize.com</span></div>
            <div><span className="pl-5 pr-2">-</span>Address:</div>
            <div className="!m-0 font-semibold pl-5 pr-2">Consentize Inc</div>
            <div className="!m-0 font-semibold pl-5 pr-2">701 Ellicott Street</div>
            <div className="!m-0 font-semibold pl-5 pr-2">BLD# 266</div>
            <div className="!m-0 font-semibold pl-5 pr-2">Buffalo, NY 14203</div>


        </div>
    );
};

export default TermsAndConditions;

