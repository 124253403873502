import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import API from "../../apiClient/NeoTrustAPI";
import { STATUS, handleErrorMsg } from "../../utils/common";
import { TOAST_TYPE, showToastUtil } from "../../components/banner/BannerUtil";

interface IProps {
    onNextStep: () => void; // Callback for the next step
}

const ShowMnemonic: React.FC<IProps> = ({ onNextStep }) => {
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance

    const [srp, setSrp] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [loader, setLoader] = useState<STATUS>(STATUS.PROCESSING);
    const [isCopied, setIsCopied] = useState(false); // State to track if SRP is copied

    useEffect(() => {
        const getSRP = async () => {
            try {
                const response = await api.post(`/user/srp`, {});
                const responseData = response.data;
                setSrp(responseData?.data?.srp);
                setLoader(STATUS.SUCCESS);
            } catch (e: any) {
                console.error('Error generating secret recovery phase.', e);
                const errMsg = handleErrorMsg(e, 'Error generating secret recovery phase.');
                showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
                setErrMsg(errMsg);
                setLoader(STATUS.ERROR);
            }
        };
        getSRP();
    }, []);

    const handleCopyToClipboard = () => {
        // Copy the SRP to clipboard
        navigator.clipboard.writeText(srp);
        setIsCopied(true); // Update the state to indicate it is copied
    };

    const handleSubmit = () => {
        if (isCopied) {
            onNextStep();
        }
    };

    return (
        <div className="bg-white shadow sm:rounded-lg flex flex-col items-center max-w-7xl mx-auto my-6">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg text-center font-semibold leading-6 text-bluegray-900 pb-3 flex justify-center">
                    <span>Save Secret Recovery Phrase</span>
                    <span className="relative group inline-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-flex ml-3 text-ntpurple cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <span className="absolute top-10 scale-0 transition-all min-w-[300px] right-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 rounded-xl bg-gray-800 p-2 text-base text-white group-hover:scale-100">
                            {"A Seed Phrase: Your Wallet's Emergency Key. Keep it safe to recover access to your documents if you ever lose access and to prove ownership of your wallet. Store this in a secure place and be sure to never share it with anyone.  This is the only way to recover your wallet password."}
                        </span>
                    </span>
                </h3>
                <div className="mt-2">
                    {/* <div className="mt-2 sm:flex sm:items-start sm:justify-between"> */}
                    {loader === STATUS.PROCESSING && (
                        <div className="w-full p-4 flex justify-center items-center h-40">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-ntpurple"></div>
                        </div>
                    )}
                    {loader === STATUS.ERROR && (
                        <div className="w-full p-4 text-center text-red-500">
                            {errMsg}
                        </div>
                    )}
                    {loader === STATUS.SUCCESS && (
                        <>
                            {/* <div className="max-w-3xl text-lg text-gray-800">
                                <p>{srp}</p>
                            </div> */}
                            <div className="mt-2 grid grid-cols-3 gap-4 overflow-x-auto">
                                {srp.split(' ').map((word, index) => (
                                    <div key={index} className="flex items-center">
                                        <span className="w-[25px] ml-3">{index + 1})</span>
                                        <div className="border border-bluegray-300 rounded-md px-3 py-2 text-center sm:min-w-[100px]">
                                            <span className="font-semisbold">{word}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-5 text-center">
                                <button
                                    type="button"
                                    className={`inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600 my-auto`}
                                    onClick={handleCopyToClipboard}
                                // disabled={isCopied} // Disable the button when SRP is copied
                                >
                                    {isCopied ? "Copied!" : "Copy to Clipboard"}
                                </button>
                            </div>

                        </>
                    )}
                </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
                <button
                    type="button"
                    className={`inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600 ${!isCopied ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    onClick={handleSubmit}
                    disabled={!isCopied} // Disable the "Next" button until SRP is copied
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ShowMnemonic;
