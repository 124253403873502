import React, { useEffect, useState } from 'react';
import Steps from './Steps';
import ProfileDetailsForm from './ProfileDetailsForm';
import ShowMnemonic from './ShowMnemonic';
import SRPInputComponent from './CreatePassword';
import CheckoutPage from './Checkout';
import { useLocation } from 'react-router-dom';
import { UserProfileAddress } from '../../utils/types';
import PlansComponent from '../plans/PlansComponent';

const OnBoardingPage: React.FC = () => {
	const location = useLocation();
	// State to keep track of the current step
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [price_id, setPriceID] = useState<string>('');
	const [userProfileAddress, setUserProfileAddress] = useState<UserProfileAddress>({
		fullName: '',
		addressLine1: '',
		addressLine2: '',
		city: '',
		state: '',
		country: '',
		zipCode: '',
	});

	const steps = [
		{ id: '01', name: 'Profile Details', status: currentStep === 0 ? 'current' : 'complete' },
		{ id: '02', name: 'Generate Secret Recovery Phrase', status: currentStep === 1 ? 'current' : currentStep > 1 ? 'complete' : 'upcoming' },
		{ id: '03', name: 'Create Password', status: currentStep === 2 ? 'current' : currentStep > 2 ? 'complete' : 'upcoming' },
		{ id: '04', name: 'Choose Plan', status: currentStep === 3 ? 'current' : currentStep > 3 ? 'complete' : 'upcoming' },
		{ id: '05', name: 'Checkout', status: currentStep === 4 ? 'current' : currentStep > 4 ? 'complete' : 'upcoming' },
		// Add more steps as needed
	];

	// Callback function to increment the current step
	const handleNextStep = () => setCurrentStep(currentStep + 1);
	const handlePriceID = (price_id: string) => setPriceID(price_id);
	const handleUserProfileAddress = (profileAddress: UserProfileAddress) => setUserProfileAddress(profileAddress);

	useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParam = searchParams.get('step');

        if (stepParam === 'plan') {
            setCurrentStep(3);
        }
    }, [location.search]);

	return (
		<>
			<div className="page-container">
				<div className="content-container">
					<div className="center-header py-6">
						<h1 className="text-4xl font-bold text-center text-ntpurple">Get Started with Our Easy Onboarding Process</h1>
					</div>
					<Steps steps={steps} />
					{currentStep === 0 && <ProfileDetailsForm onNextStep={handleNextStep} initValue={userProfileAddress} handleUserProfileAddress={handleUserProfileAddress} />}
					{currentStep === 1 && <ShowMnemonic onNextStep={handleNextStep} />}
					{currentStep === 2 && <SRPInputComponent onNextStep={handleNextStep} userProfileAddress={userProfileAddress} />}
					{currentStep === 3 && <PlansComponent isOnboardingPage={true} onNextStep={handleNextStep} handlePriceID={handlePriceID} />}
					{currentStep === 4 && <CheckoutPage payedPlan={true} price_id={price_id} />}
					{/* Add more conditions for other steps as needed */}
				</div>
			</div>
		</>
	);
};

export default OnBoardingPage;
