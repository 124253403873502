import React from 'react';

const trustedSectionText = [
    {
        title: 'A Trusted Team',
        description: 'The Neotrust team is a group of innovators with deep experience in technology, security, and blockchain. Our co-founders, Caesar Medel CEO and Corey Lanzing COO, along with our Steve Burg CTO , bring decades of expertise in building technology solutions that shape industries. Together, they’ve infused trust and transparency into every aspect of Neotrust, making it the gold standard for digital trust.',
    },
    {
        title: 'Trusted Technology',
        description: 'Neotrust’s patented technology allows users to securely manage and verify their digital files through our intuitive platform. With a few simple steps, users can timestamp and certify their documents, ensuring that ownership and authenticity are locked in for the future. By utilizing blockchain technology, we offer a foolproof way to protect your digital assets from tampering and fraud.',
    }
];

const TrustedSection: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 p-8 text-i-indigo">
                {trustedSectionText.map((section, index) => (
                    <div key={index} className="flex flex-col">
                        <div className="text-3xl font-archivo mb-4">{section.title}</div>
                        <div className="text-lg text-i-gray-dark leading-6">{section.description}</div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TrustedSection;
