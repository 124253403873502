import React from 'react';

const timelineEvents = [
    {
        date: 'Jan - Mar 2019',
        description: 'Version 1.0 Project Neoware is created and Alpha trials begin at the University.',
    },
    {
        date: 'June 2019',
        description: 'Rebranded as Neotrust and patent gets filed at USPTO office.',
        alignRight: true,
    },
    {
        date: 'July 2022',
        description: 'Neotrust is officially patented after being challenged.',
    },
    {
        date: 'Oct 2022',
        description: 'Neotrust headquarters moves operations to WNY, Buffalo.',
        alignRight: true,
    },
    {
        date: 'Jan 2023',
        description: 'Partnership with University at Buffalo cultivator program.',
    },
    {
        date: 'Aug 2023',
        description: 'Neotrust public web portal opens for public and small business use.',
        alignRight: true,
    },
    {
        date: 'June 2024',
        description: 'Launch of Canadian operations.',
    },
    {
        date: 'Now',
        description: 'Neotrust continues to grow and move forward building partnerships.',
        alignRight: true,
    },
];

const TimelineSection: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="container mx-auto p-8 text-center">
                <div className="text-3xl font-archivo text-i-indigo py-4">How We Built Trust</div>
                <div className="text-i-indigo-dark text-xl pb-8">
                    {'The cofounders of Neotrust asked themselves a big question: "What if we could establish ownership of our digital assets instantly and link them securely to our identities?" This is the journey to build digital trust from that point forward.'}
                </div>

                <div className="relative">
                    {timelineEvents.map((event, index) => (
                        <div key={index} className={`mb-8 flex ${event.alignRight ? 'flex-row-reverse' : 'flex-row'} items-center`}>
                            <div className={`w-8 h-8 rounded-full bg-gray-400 mx-4 flex-shrink-0`}></div>
                            <div className={`flex-1 bg-gray-100 p-4 rounded-lg shadow-lg ${event.alignRight ? 'text-right' : 'text-left'}`}>
                                <div className="text-lg font-semibold text-i-indigo">{event.date}</div>
                                <div className="text-gray-600">{event.description}</div>
                            </div>
                        </div>
                    ))}
                    {/* Vertical Line */}
                    {/* <div className="absolute inset-0 left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-400"></div> */}
                </div>
            </div>
        </section>
    );
};

export default TimelineSection;
