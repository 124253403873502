import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import FAQsPage from '../pages/faqs/FAQsPage';
import OnBoardingPage from '../pages/onboarding/OnBoardingPage';
import StampComponent from '../pages/stamp/Stamp';
import VerifyComponent from '../pages/verify/Verify';
import Download from '../pages/download/Download';
import PaymentSuccess from '../pages/onboarding/PaymentSuccess';
import LoginChecker from '../pages/auth/LoginChecker';
import PaymentCancelled from '../pages/onboarding/PaymentCancelled';
import DashboardRouter from '../pages/dashboard/DashboardRouter';
import Profile from '../pages/profile/Profile';
import ContactPage from '../pages/contact/ContactPage';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsAndConditions from '../pages/terms-and-conditions/TermsAndConditions';
// import PlansComponent from '../pages/plans/PlansComponent';
import Temp from './Temp';
import AppLayout from '../layouts/AppLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import HomePage from '../pages/home/HomePage';
import TrustPage from '../pages/trust/TrustPage';
import PlansPage from '../pages/plans/PlansPage';
import CompanyPage from '../pages/company/CompanyPage';

const AppRouter: React.FC = () => {

    return (
        <Routes>
            <Route element={<AppLayout />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/trust' element={<TrustPage />} />
                <Route path='/plans' element={<PlansPage />} />
                <Route path='/company' element={<CompanyPage />} />
                {/* below pages to be redesigned */}
                <Route path='/faqs' element={<FAQsPage />} />
                <Route path='/onboarding' element={<OnBoardingPage />} />
                {/* <Route path='/dashboard' element={<DashboardRouter />} /> */}
                <Route path='/stamp' element={<StampComponent />} />
                <Route path='/download/:transactionId' element={<Download />} />
                <Route path='/verify' element={<VerifyComponent />} />
                <Route path='/login-checker' element={<LoginChecker />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/payment/success' element={<PaymentSuccess />} />
                <Route path='/payment/canceled' element={<PaymentCancelled />} />
                <Route path='/temp' element={<Temp />} />
            </Route>

            <Route path="/dashboard/*" element={<DashboardLayout />}>
                <Route index element={<DashboardRouter />} />
                <Route path="*" element={<DashboardRouter />} />
            </Route>

            {/* Redirect any unknown routes to the home page */}
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    );
}

export default AppRouter;
