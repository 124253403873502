import React from 'react';

type BackgroundContainerProps = React.PropsWithChildren<{
    bgImage: string;
    className?: string;
    aspectRatio?: string; // 'width / height'
    maxHeight?: string;
}>;

const BackgroundContainer: React.FC<BackgroundContainerProps> = ({ bgImage, children, className, aspectRatio, maxHeight }) => {
    return (
        <div className={`relative ${className}`}>
            <div
                className="absolute inset-0 bg-no-repeat bg-cover bg-center z-0"
                style={{ backgroundImage: `url(${bgImage})` }}
            ></div>
            <div className="relative w-full flex flex-col items-center justify-center z-10"
                style={{
                    ...(aspectRatio ? { aspectRatio } : {}),
                    ...(maxHeight ? { maxHeight } : {})
                }}
            >
                {children}
            </div>
        </div >
    );
};

export default BackgroundContainer;