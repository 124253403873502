import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useRef, useEffect } from "react";
import API from "../../apiClient/NeoTrustAPI";
import { STATUS, handleErrorMsg } from "../../utils/common";
import { showToastUtil, TOAST_TYPE } from "../../components/banner/BannerUtil";
import { UserProfileAddress } from "../../utils/types";
import { UserInfoContext } from "../../components/UserInfoProvider";

interface IProps {
    // onNextStep: (recoveryPhrase: string, password: string) => void; // Callback for the next step
    onNextStep: () => void; // Callback for the next step
    userProfileAddress: UserProfileAddress
}

const SRPInputComponent: React.FC<IProps> = ({ onNextStep, userProfileAddress }) => {
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
    const { refreshUserInfo } = React.useContext(UserInfoContext);

    const [realWorldIdentity, setRealWorldIdentity] = useState("");

    const handleRealWorldIdentityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRealWorldIdentity(event.target.value);
    };


    const numWords = 12; // Number of words in the Secret Recovery Phrase
    const [recoveryWords, setRecoveryWords] = useState<string[]>(new Array(numWords).fill(""));
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setRecoveryWords((prevWords) => {
            const newWords = [...prevWords];
            newWords[index] = value;
            return newWords;
        });

        if (index < numWords - 1 && value !== "") {
            // Focus on the next input when there is a value and not the last input
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.trim());
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value.trim());
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault(); // Prevent the default paste behavior
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        if (clipboardData) {
            const pastedText = clipboardData.getData("text");
            const words = pastedText.trim().split(/\s+/);

            setRecoveryWords((prevWords) => {
                const newWords = [...prevWords];
                for (let i = 0; i < numWords; i++) {
                    if (i < words.length) {
                        newWords[i] = words[i];
                    } else {
                        newWords[i] = "";
                    }
                }
                return newWords;
            });
        }
    };

    const getKeys = async (srp: string, password: string, realWorldIdentity: string) => {
        try {
            const { fullName, ...address } = userProfileAddress;
            const response = await api.post(`/user/keys`, {
                srp: srp,
                password: password,
                realWorldIdentity: realWorldIdentity.trim(),
                fullName: fullName,
                address: address
            });
            const responseData = response.data;
            // console.log(responseData);
            refreshUserInfo();
            // setLoader(STATUS.SUCCESS);
            onNextStep();
        } catch (e: any) {
            console.error('Error generating key pairs and wallet address.', e);
            const errMsg = handleErrorMsg(e, 'Error generating key pairs and wallet address.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
            // setLoader(STATUS.ERROR);
        }
    };

    const handleSubmit = () => {
        // Validate the form (optional) and handle submission here
        const joinedWords = recoveryWords.join(" ");
        getKeys(joinedWords, password, realWorldIdentity);
        // onNextStep(joinedWords, password); // Call the callback to move to the next step with SRP and password

    };

    const isNextButtonDisabled = !recoveryWords.every((word) => word !== "") || password.length < 8 || password !== confirmPassword || realWorldIdentity === '';

    return (
        <div className="bg-white shadow sm:rounded-lg flex flex-col items-center max-w-7xl mx-auto my-6 p-4">
            <h3 className="text-lg font-semibold leading-6 text-bluegray-900 flex justify-center">
                <span>Enter/Paste Secret Recovery Phrase</span>
                <span className="relative group inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-flex ml-3 text-ntpurple cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <span className="absolute top-10 scale-0 transition-all min-w-[300px] right-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 rounded-xl bg-gray-800 p-2 text-base text-white group-hover:scale-100">
                        {"Copy and paste your seed phrase from the previous page. Be sure to save this is a secure spot and be sure not to share it with anyone.  This is your only way to recover your account."}
                    </span>
                </span>
            </h3>
            <div className="grid grid-cols-3 gap-4 mt-4">
                {recoveryWords.map((word, index) => (
                    <input
                        key={index}
                        type="text"
                        value={word}
                        className="border border-bluegray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                        placeholder={`Word ${index + 1}`}
                        onChange={(event) => handleInputChange(index, event)}
                        onPaste={handlePaste}
                        ref={(el) => (inputRefs.current[index] = el)}
                    />
                ))}
            </div>

            <h3 className="mt-6 text-base font-semibold leading-6 text-bluegray-900 flex justify-center">
                <span>Enter Your Real World Identity</span>
                <span className="relative group inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-flex ml-3 text-ntpurple cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <span className="absolute top-10 scale-0 transition-all min-w-[300px] right-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 rounded-xl bg-gray-800 p-2 text-base text-white group-hover:scale-100">
                        {"Whenever you timestamp a document on Neotrust, we assign information to that certificate relating to the owner for that file. This real-world Identity you enter here will be the publicly attested ID associated with your files when they are verified."}
                    </span>
                </span>
            </h3>
            <div className="mt-2">
                <input
                    type="text"
                    value={realWorldIdentity}
                    className="border border-bluegray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Real World Identity"
                    onChange={handleRealWorldIdentityChange}
                />
            </div>

            <h3 className="mt-6 text-base font-semibold leading-6 text-bluegray-900 flex justify-center">
                <span>Create New Password For Encrypting Private Key</span>
                <span className="relative group inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-flex ml-3 text-ntpurple cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <span className="absolute top-10 scale-0 transition-all min-w-[300px] right-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 rounded-xl bg-gray-800 p-2 text-base text-white group-hover:scale-100">
                        {"This password is separate from your account password. This will be used to sign and download your timestamped files and prove ownership off your wallet."}
                    </span>
                </span>
            </h3>

            <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">Enter Password</label>
                <input
                    type="password"
                    value={password}
                    className="border border-bluegray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Password"
                    onChange={handlePasswordChange}
                />
            </div>
            {password.length > 0 && password.length < 8 && (
                <p className="text-red-500 text-sm mt-1">Password must be at least 8 characters long.</p>
            )}

            <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                <input
                    type="password"
                    value={confirmPassword}
                    className="border border-bluegray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Confirm Password"
                    onChange={handleConfirmPasswordChange}
                />
            </div>

            <button
                type="button"
                className={`mt-6 inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600 ${isNextButtonDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleSubmit}
                disabled={isNextButtonDisabled}
            >
                Next
            </button>
        </div>
    );
};

export default SRPInputComponent;
