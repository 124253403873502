/* */
/* User Transactions related types */
export interface UserTransactionsAPIResponse {
    status: string;
    data: {
        transactions: UserTransaction[],
        totalCount: number,
    }
}

interface UserTransaction {
    _id: string;
    transactionStatus: TransactionStatusKeys;
    transactionDateTime: string;
    featureName: string;
    onchainTxResult: any;
    fileMetadata: FileMetadata[]
}

interface FileMetadata {
    _id: string;
    transactionId: string;
    fileName: string;
    fileType: string;
    fileSize: number;
    fileLastModified: number;
    fileHash: string;
    fileObjectKey: string;
}

export enum TransactionTypeKeys {
    timestamp = 'timestamp',
    timestamp_and_store = 'timestamp_and_store',
    verify = 'verify',
    info = 'info'
}

export enum TransactionTypeValues {
    timestamp = 'Timestamp',
    timestamp_and_store = 'Timestamp and Store',
    verify = 'Verify',
    info = 'Download Archive'
}

export enum TransactionStatusKeys {
    waiting = "waiting",
    inProgress = "inProgress",
    recorded = "recorded",
    downloaded = "downloaded",
    initiated = "initiated",
    failed = "failed",
    successful = "successful",
}
export enum TransactionStatusValues {
    waiting = "Waiting",
    inProgress = "In Progress",
    recorded = "Recorded",
    downloaded = "Downloaded",
    initiated = "Initiated",
    failed = "Failed",
    successful = "Successful",
}

/* User Utilization related types */
export interface UserUtilizationAPIResponse {
    status: string;
    data: FeatureUsage[]
}

export interface FeatureUsage {
    currentUsage: number;
    maxQuota: number;
    featureName: string
}

/* user profile/address types */
export type UserProfileAddress = UserAddress & {
    fullName: string;
    // [k: string]: string;
}

export type UserAddress = {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}