import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../assets/v2/logo/logo.svg';
import IconCall from '../../assets/v2/icons/icon-call.svg';
import IconIdentity from '../../assets/v2/icons/icon-identity.svg';
import IconHome from '../../assets/v2/icon-home.svg';
import IconLogout from '../../assets/v2/icon-logout.svg';

const DashboardHeader: React.FC = () => {
    const { logout } = useAuth0();
    const linkClasses = 'ibtn ibtn-primary text-sm min-w-[100px]';

    return (
        <header className='bg-white shadow-md'>
            <div className='container mx-auto p-4 flex items-center justify-between'>

                {/* Logo */}
                <div className='flex items-center'>
                    <Link to='/'>
                        <img src={Logo} alt='Neotrust Logo' className='h-16 w-auto' />
                    </Link>
                </div>

                {/* Navigation Buttons */}
                <nav className='hidden md:flex space-x-4'>
                    <Link to='/dashboard/portfolio' className={linkClasses}>Portfolio</Link>
                    <Link to='/dashboard/stamp' className={linkClasses}>Stamp</Link>
                    <Link to='/dashboard/verify' className={linkClasses}>Verify</Link>
                </nav>

                {/* User Actions */}
                <div className='flex items-center space-x-4'>
                    <Link to='/' className=''>
                        <img className='w-auto h-5' src={IconHome} alt='Home' />
                    </Link>
                    <Link to='/contact' className=''>
                        <img className='w-auto h-5' src={IconCall} alt='Contact' />
                    </Link>
                    <Link to='/profile' className=''>
                        <img className='w-auto h-5' src={IconIdentity} alt='Profile' />
                    </Link>
                    <div onClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} className="cursor-pointer">
                        <img className="w-auto h-5" src={IconLogout} alt="Logout" />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default DashboardHeader;
