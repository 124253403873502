import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

const features = [
    { feature: 'Stamps', essential: '50', advanced: '500', global: 'Unlimited' },
    { feature: 'Analytics', essential: true, advanced: true, global: true },
    { feature: 'Advanced Analytics', essential: false, advanced: true, global: true },
    { feature: 'File Mapping', essential: false, advanced: true, global: true },
    { feature: 'Personalized Trust Dashboard', essential: true, advanced: true, global: true },
    { feature: 'Monitoring & Alerts', essential: false, advanced: true, global: true },
    { feature: 'AI-Powered Threat Detection', essential: false, advanced: true, global: true },
    { feature: 'GDPR and HIPAA Compliance', essential: true, advanced: true, global: true },
    { feature: 'Multi-Factor Authentication', essential: false, advanced: false, global: true },
    { feature: 'Audit Trails', essential: false, advanced: false, global: true },
    { feature: 'API Integration', essential: false, advanced: false, global: true },
    { feature: 'Custom API', essential: false, advanced: false, global: true },
    { feature: 'Dedicated Account Manager', essential: false, advanced: false, global: true },
    { feature: 'White Labeling', essential: false, advanced: false, global: true }
];

const ComparisonTableSection: React.FC = () => {
    return (
        <section className='bg-white'>
            <div className='container mx-auto p-8'>
                <div className='text-center pt-4 pb-8'>
                    <div className='text-3xl font-archivo text-i-indigo'>Compare Plans</div>
                </div>
                <div className='overflow-x-auto'>
                    <table className='table-auto w-full text-center'>
                        <thead>
                            <tr className='bg-i-indigo text-white'>
                                <th className='p-4'>Features</th>
                                <th className='p-4'>Essential</th>
                                <th className='p-4'>Advanced</th>
                                <th className='p-4'>Global</th>
                            </tr>
                        </thead>
                        <tbody>
                            {features.map((featureItem, index) => (
                                <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                    <td className='p-4 font-semibold'>{featureItem.feature}</td>
                                    <td className='p-4'>
                                        {typeof featureItem.essential === 'string' ? (
                                            featureItem.essential
                                        ) : featureItem.essential ? (
                                            <CheckIcon className='h-5 w-5 text-i-indigo mx-auto' aria-hidden='true' />
                                        ) : (
                                            <XMarkIcon className='h-5 w-5 text-red-500 mx-auto' aria-hidden='true' />
                                        )}
                                    </td>
                                    <td className='p-4'>
                                        {typeof featureItem.advanced === 'string' ? (
                                            featureItem.advanced
                                        ) : featureItem.advanced ? (
                                            <CheckIcon className='h-5 w-5 text-i-indigo mx-auto' aria-hidden='true' />
                                        ) : (
                                            <XMarkIcon className='h-5 w-5 text-red-500 mx-auto' aria-hidden='true' />
                                        )}
                                    </td>
                                    <td className='p-4'>
                                        {typeof featureItem.global === 'string' ? (
                                            featureItem.global
                                        ) : featureItem.global ? (
                                            <CheckIcon className='h-5 w-5 text-i-indigo mx-auto' aria-hidden='true' />
                                        ) : (
                                            <XMarkIcon className='h-5 w-5 text-red-500 mx-auto' aria-hidden='true' />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default ComparisonTableSection;
