import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { EnvConfig } from '../utils/envConfig';

interface Auth0ProviderComponentProps {
    children: React.ReactNode;
}


export const Auth0ProviderComponent: React.FC<Auth0ProviderComponentProps> = (props) => {
    const { children } = props;
    const navigate = useNavigate();

    const domain = EnvConfig.AUTH0_DOMAIN;
    const clientId = EnvConfig.AUTH0_CLIENTID
    const redirectUri = EnvConfig.AUTH0_CALLBACK_URL;
    const audience = EnvConfig.AUTH0_AUDIENCE;

    const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState, user) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        console.error("Auth0 environment variables are missing.");
        return null;
    }


    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: audience,
                scope: 'openid profile email',
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
}
