import React, { useEffect, useState } from 'react';
import { UserProfileAddress } from '../../utils/types';
import ProfileDetailsForm from '../onboarding/ProfileDetailsForm';
import { UserInfo, UserInfoAPIResponse, checkValidUserPlan } from '../../utils/userUtils';
import API from '../../apiClient/NeoTrustAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { TOAST_TYPE, showToastUtil } from '../../components/banner/BannerUtil';
import { handleErrorMsg } from '../../utils/common';
import { UserInfoContext } from '../../components/UserInfoProvider';

interface IProps {
    // onNextStep: () => void; // Callback for the next step
}

const ProfileInfoDisplay: React.FC<IProps> = () => {
    const { logout } = useAuth0();

    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance

    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [userProfileAddress, setUserProfileAddress] = useState<UserProfileAddress>({
        fullName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
    });
    const [isEditView, setEditView] = useState<boolean>(false);
    const { userInfoData, refreshUserInfo } = React.useContext(UserInfoContext);


    useEffect(() => {
        if (!userInfoData) return;
        const userInfo = userInfoData.user[0];
        setUserInfo(userInfo);
        const fullName = userInfo?.userName;
        const address = userInfo?.address?.length ? userInfo?.address[0] : {};
        setUserProfileAddress({
            ...userProfileAddress,
            fullName,
            ...address
        });
    }, [userInfoData]);

    const onEditClick = () => {
        setEditView(true);
    }

    const update = async (profileAddress: UserProfileAddress) => {
        try {
            const response = await api.put('/user/address', {
                ...profileAddress
            });
            refreshUserInfo();
            return;
        } catch (e: any) {
            console.error('Error updating user info:', e);
            const errMsg = handleErrorMsg(e, 'Error updating user info:');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
        }
    };

    const onPaymentPortalClick = async () => {
        try {
            const response = await api.post('/payment/create-customer-portal-session');
            const responseData = response.data;
            responseData.url && window.open(responseData.url, '_blank'); // Open URL in a new tab/window            return;
            return;
        } catch (e: any) {
            console.error('Error creating payment portal session:', e);
            const errMsg = handleErrorMsg(e, 'Error creating payment portal session.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
        }
    };

    const handleUserProfileAddress = async (profileAddress: UserProfileAddress) => {
        try {
            await update(profileAddress);
            setUserProfileAddress(profileAddress)
            setEditView(false);
        } catch (e: any) {
            console.error('Error updating user info:', e);
            showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error updating user info. Please try agin after sometime.' });
            setEditView(false);
        }
    };

    return (
        <>
            {isEditView
                ? <ProfileDetailsForm initValue={userProfileAddress} handleUserProfileAddress={handleUserProfileAddress} onNextStep={() => { }} />
                :
                <div className="p-12 m-4 mx-auto flex flex-col min-w-[50%] max-w-7xl items-center shadow rounded-2xl bg-white">
                    <div className="border-b pb-6 border-bluegray-900/10 w-full">
                        <h2 className="pb-6 text-base font-semibold leading-7 text-bluegray-900">Personal Information
                            <button className="ml-6 rounded-full bg-ntpurple px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple"
                                onClick={onEditClick}
                            >Edit</button>
                        </h2>
                        <div className="border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Full name:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.fullName}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Address Line 1:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.addressLine1}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Address Line 2:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.addressLine2 ?? ''}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">City:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.city}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">State / Province:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.state}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Country:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.country}</dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">ZIP / Postal code:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userProfileAddress.zipCode}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            }
            {
                <div className="p-12 m-4 mx-auto flex flex-col min-w-[50%] max-w-7xl items-center shadow rounded-2xl bg-white">
                    <div className="border-b pb-6 border-bluegray-900/10 w-full">
                        <h2 className="pb-6 text-base font-semibold leading-7 text-bluegray-900">Wallet Information (Coming Soon)</h2>
                        <div className="border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Blockchain Address (Bitcoin):</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userInfo?.bChainAddress}</dd>
                                </div>
                            </dl>
                            {/* <dl className="divide-y divide-gray-100">
                                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm font-medium text-gray-900">Publick Key:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userInfo?.publicKey}</dd>
                                </div>
                            </dl> */}
                        </div>
                    </div>
                </div>
            }
            {
                <div className="p-12 m-4 mx-auto flex flex-col min-w-[50%] max-w-7xl items-center shadow rounded-2xl bg-white">
                    {/* <div className="border-b pb-6 border-bluegray-900/10 w-full"> */}
                    <h2 className="text-base font-semibold leading-7 text-bluegray-900">
                        <button className="rounded-full bg-ntpurple px-6 py-2 text-md font-semibold text-white shadow-sm hover:bg-ntpurple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple"
                            onClick={onPaymentPortalClick}
                        >Manage Payment Profile</button>
                    </h2>
                    <button onClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }) }} className="mt-6 text-md font-semibold leading-6 py-2 px-6 bg-ntpurple hover:bg-ntpurple-600 text-white transition ease-in duration-200 text-center shadow-md focus:outline-none rounded-full">
                        Logout
                    </button>

                    {/* </div> */}
                </div>
            }

        </>
    );
};

export default ProfileInfoDisplay;
