import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import API from '../../apiClient/NeoTrustAPI';
import { TOAST_TYPE, showToastUtil } from '../../components/banner/BannerUtil';
import { STATUS, handleErrorMsg } from '../../utils/common';
import bglogo from '../../assets/home/uploadBackground.svg';
import iconlogo from '../../assets/icons/downloadarchive-icon.svg';

interface FileMetadata {
	transactionId: string;
	fileName: string;
	fileType: string;
	fileSize: number;
	fileLastModified: number;
	fileHash: string;
	fileObjectKey: string;
	signedR2Url: string;
	uploaded: boolean;
	matched: boolean;
	file: File;
}

const Download: React.FC = () => {
	const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance

	const { transactionId } = useParams<{ transactionId: string }>();
	const [infoTransactionId, setInfoTransactionId] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [certificateFile, setCertificateFile] = useState<File | null>(null);
	const [filesMetadata, setFilesMetadata] = useState<FileMetadata[]>([]);
	const [isMapped, setIsMapped] = useState<boolean>(false);
	const [contentLoader, setContentLoader] = useState<STATUS>(STATUS.PROCESSING);
	const [loader, setLoader] = useState<STATUS>(STATUS.NONE);
	const [enableDownload, toggleEnableDownload] = useState<boolean>(false);

	useEffect(() => {
		// Fetch files metadata
		const fetchFilesMetadata = async () => {
			try {
				const response = await api.get(`/transaction/getFilesMetadata/${transactionId}`);
				// if (response.status !== 200) throw new Error('Error fetching transaction info.')

				const data = response.data;
				const filesMetaData: FileMetadata[] = data.filesMetaData;
				let isMapped: boolean = true;
				filesMetaData.forEach((fileMetadata: FileMetadata) => {
					isMapped = isMapped && !!fileMetadata.signedR2Url
				});
				setIsMapped(isMapped);
				setFilesMetadata(filesMetaData);
				setContentLoader(STATUS.SUCCESS);
			} catch (e: any) {
				console.error('Error fetching files metadata for transaction id:', transactionId, e);
				const errMsg = handleErrorMsg(e, 'Error fetching transaction info.');
				showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
				setContentLoader(STATUS.ERROR);
			}
		};

		// if (transactionId) fetchFilesMetadata();
		fetchFilesMetadata();
	}, [transactionId]);

	// Handle password input change
	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	// Handle generate certificate button click
	const handleGenerateCertificate = async () => {
		try {
			const response = await api.post(`/transaction/certificate/${transactionId}`, {
				password: password,
			}, {
				responseType: 'arraybuffer', // Specify the response type as arraybuffer
			});
			console.log('iam here', response);

			if (response.status !== 200) throw new Error('Failed to generate pdf certificate for transaction.')
			const infoTransactionId = response.headers['infotransactionid'];
			setInfoTransactionId(infoTransactionId as string);
			const certificateResponse = response.data;

			const certificateBlob = new Blob([certificateResponse], { type: 'application/pdf' });
			const certificate = new File([certificateBlob], 'certificate.pdf', { type: 'application/pdf' });

			// Store the certificate file
			setCertificateFile(certificate);
		} catch (e: any) {
			console.error('Error generating pdf certificate:', e);
			const data = e?.response?.data;
			const uint8Array = new Uint8Array(data);
			const obj_str = new TextDecoder().decode(uint8Array);
			const obj = JSON.parse(obj_str);
			showToastUtil({ status: TOAST_TYPE.ERROR, message: obj?.message || 'Failed to generate pdf certificate for transaction.' });
		}
	};

	// Handle download button click
	const handleDownload = async () => {
		try {
			setLoader(STATUS.PROCESSING);
			// Create a new zip instance
			const zip = new JSZip();

			// Create a folder named "files" in the zip
			const filesFolder = zip.folder('files');

			// Add the certificate to the top level of the zip
			if (certificateFile) {
				zip.file(certificateFile.name, certificateFile);
			}

			// Add each file to the "files" folder
			if (isMapped) {
				// Download each file using signedR2Url
				for (const fileMetadata of filesMetadata) {
					const response = await fetch(fileMetadata.signedR2Url, {
						method: 'GET',
					});
					const fileBlob = await response.blob();
					filesFolder?.file(fileMetadata.fileName, fileBlob);
				}
			} else {
				// Add user-uploaded files to the "files" folder
				for (const file of filesMetadata) {
					filesFolder?.file(file.fileName, file.file);
				}
			}
			// Generate the zip content
			const content = await zip.generateAsync({ type: 'blob' });
			// Trigger the download
			saveAs(content, `NeoArchive_${transactionId}.zip`);

			const response = await api.post(`/transaction/archiveDownloaded/`, {
				transactionId: transactionId,
				infoTransactionId: infoTransactionId
			});

			setLoader(STATUS.SUCCESS);
		} catch (e: any) {
			console.error('Error generating Neotrust archive:', e);
			const errMsg = handleErrorMsg(e, 'Error generating Neotrust archive.');
			showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
			setLoader(STATUS.ERROR);
		}
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, fileMetadata: FileMetadata) => {
		const uploadedFile = event.target.files && event.target.files[0]; // Get the uploaded file of type File
		if (uploadedFile) {
			checkFileUpload(uploadedFile, fileMetadata);
		}
	};

	const checkFileUpload = async (file: File, fileMetadata: FileMetadata) => {
		const arrayBuffer = await file.arrayBuffer();
		const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

		if (fileMetadata.fileHash === hashHex) {
			const updatedFilesMetadata = filesMetadata.map((filedata) =>
				filedata.fileHash === fileMetadata.fileHash
					? { ...filedata, uploaded: true, matched: true, file: file }
					: filedata
			);
			setFilesMetadata(updatedFilesMetadata);
		} else {
			const updatedFilesMetadata = filesMetadata.map((filedata) =>
				filedata.fileHash === fileMetadata.fileHash
					? { ...filedata, uploaded: true, matched: false, file: file }
					: filedata
			);
			setFilesMetadata(updatedFilesMetadata);
		}
	};

	useEffect(() => {
		if (certificateFile) {
			if (isMapped) {
				toggleEnableDownload(true);
			} else {
				let allMatched = true;
				filesMetadata.forEach((fileMetadata: FileMetadata) => {
					allMatched = allMatched && !!fileMetadata.file && fileMetadata.matched;
				});
				toggleEnableDownload(allMatched);
			}
		} else {
			toggleEnableDownload(false);
		}
	}, [certificateFile, isMapped, filesMetadata]);

	return (
		<div className="page-container bg-offwhite dark">
			<div className="container rounded-3xl m-6 p-6 px-6 mx-auto relative bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bglogo})` }}>
				<div className="flex flex-wrap justify-center dark:text-white">
					{/* Heading */}
                    <img className="w-20 h-auto -my-3" src={iconlogo} alt="Download" />
					<div className="w-full p-4 text-3xl font-bold text-center">Download Neotrust Archive File</div>

					{/* Spinning Loader */}
					{contentLoader === STATUS.PROCESSING && (
						<div className="w-full p-4 flex justify-center items-center h-40">
							<div className="animate-spin rounded-full h-16 w-16 border-t-2 border-ntpurple"></div>
						</div>
					)}
					{contentLoader === STATUS.ERROR && (
						<div className="w-full p-4 text-center text-red-500">
							An error occurred while fetching transaction information. Please check the URL or try again later.
						</div>
					)}

					{/* Password Input and Generate Certificate Section */}
					{contentLoader === STATUS.SUCCESS && (
						<div className="w-full p-4 text-center mx-auto">
							<label htmlFor="password" className="text-gray-500 dark:text-gray-200 text-md">
								Enter your password to generate signed certificate of ownership:
							</label>
							<div className="flex justify-center items-center mt-2">
								{certificateFile ? (
									<span className="ml-2 text-green-500">
										&#10003; Certificate generated successfully and added to archive.
									</span>
								) : (
									<>
										<input
											type="password"
											id="password"
											value={password}
											onChange={handlePasswordChange}
											className="p-2 border rounded-md dark:border-gray-700 dark:bg-gray-800 focus:outline-none focus:ring focus:ring-ntpurple"
										/>
										<button
											type="button"
											onClick={handleGenerateCertificate}
											className="ml-2 py-2 px-4 bg-ntpurple hover:bg-ntpurple-600 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-full"
										>
											Generate Certificate
										</button>
									</>
								)}

							</div>
						</div>
					)}

					{/* Files Metadata */}
					{contentLoader === STATUS.SUCCESS && (
						<div className="w-full p-4 text-center">
							{/* Display files metadata as needed */}
							{/* For example: */}
							{filesMetadata.map((file) => (
								<div key={file.fileHash} className="p-2 border rounded-md dark:border-gray-700 bg-[#2b2f42]">
									<span>File Name: {file.fileName}</span>
									{/* <span>; File Type: {file.fileType}</span> */}
									{!isMapped ? (
										<span className="ml-4">
											{!(file.uploaded && file.matched) && (
												<>

													<label htmlFor={`upload-${file.fileHash}`}
														className="cursor-pointer px-3 py-1 bg-ntpurple text-white rounded-full hover:bg-ntpurple-600 transition duration-200"
													>
														Upload file
													</label>
													<input
														id={`upload-${file.fileHash}`}
														className='hidden'
														// aria-label={`Choose file to upload for ${file.fileName}`}
														type="file"
														// accept=".pdf, .doc, .docx, .txt"  // Add accepted file types here
														onChange={(e) => handleFileUpload(e, file)}
													/>
												</>)}
											{file.uploaded && file.matched && (
												<span className="ml-2 text-green-500">
													&#10003; Added to Archive
												</span>
											)}
											{file.uploaded && !file.matched && (
												<span className="ml-2 text-red-500">
													&#10005; Wrong File
												</span>
											)}
										</span>

									) : (
										<span className="ml-2 text-green-500">
											&#10003; Added to Archive
										</span>
									)}
								</div>
							))}
						</div>
					)}

					{/* Download Button */}
					{contentLoader === STATUS.SUCCESS && (
						<div className="w-full p-4 flex items-center justify-center">
							<button
								disabled={!enableDownload}
								type="button"
								onClick={handleDownload}
								className={`flex py-2 px-6 bg-ntpurple hover:bg-ntpurple-600 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full ${!enableDownload ? "opacity-50 cursor-not-allowed" : ""}`}
							>
								Download Archive
							</button>
						</div>
					)}

				</div>
				{(loader === STATUS.PROCESSING) && (
					<div className="absolute inset-0 flex items-center justify-center z-10 rounded-3xl bg-opacity-50 bg-bluegray-900">
						<div className="animate-spin rounded-full h-16 w-16 border-t-2 border-ntpurple"></div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Download;

