import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/header/AppHeader';
import AppFooter from '../components/footer/AppFooter';
import ScrollToTop from '../components/ScrollToTop';
import AnnouncementBar from '../components/AnnouncementBar';

const AppLayout: React.FC = () => (
    <>
        <ScrollToTop />
        <AnnouncementBar />
        <AppHeader />
        <main>
            <Outlet />
        </main>
        <AppFooter />
    </>
);

export default AppLayout;
