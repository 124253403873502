import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import API from "../../apiClient/NeoTrustAPI";
import { handleErrorMsg } from "../../utils/common";
import { TOAST_TYPE, showToastUtil } from "../../components/banner/BannerUtil";

interface IProps {
    payedPlan: boolean;
    price_id: string;
}

const CheckoutPage: React.FC<IProps> = ({ payedPlan, price_id }) => {
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance

    const handleCheckout = async () => {
        try {
            const response = await api.post(`/payment/create-checkout-session`, {
                price_id: price_id
            });
            const responseData = response.data;
            window.location.href = responseData.url; // Redirect to the payment checkout page
        } catch (e: any) {
            console.error('Error creating stripe checkout session.', e);
            const errMsg = handleErrorMsg(e, 'Error creating stripe checkout session.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
        }
    };

    return (
        <div className="bg-white shadow sm:rounded-lg flex flex-col items-center max-w-7xl mx-auto my-6 p-4">
            {payedPlan ? (
                <>
                    <h3 className="text-base font-semibold leading-6 text-bluegray-900">Checkout with Payment</h3>
                    <p className="mt-2 text-gray-800">
                        You have chosen a paid plan. Click the button below to proceed with the payment process.
                    </p>
                    <button
                        type="button"
                        className="mt-6 inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600"
                        onClick={handleCheckout}
                    >
                        Proceed to Checkout
                    </button>
                </>
            ) : (
                <>
                    <h3 className="text-base font-semibold leading-6 text-bluegray-900">You Are All Set!</h3>
                    <p className="mt-2 text-gray-800">You can now get started and access your dashboard.</p>
                    <button
                        type="button"
                        className="mt-6 inline-flex items-center rounded-md bg-ntpurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600"
                        onClick={() => {
                            // Implement your logic to go to the dashboard here
                            console.log("Go to Dashboard!");
                        }}
                    >
                        Go to Dashboard
                    </button>
                </>
            )}
        </div>
    );
};

export default CheckoutPage;
