import React, { useState } from 'react';

interface FAQItemProps {
    question: string;
    answer: string;
}
const faqs: FAQItemProps[] = [
    // {
    //     question: "What is the main purpose of our product?",
    //     answer: "Our product is designed to record and verify the integrity of digital content using blockchain technology. It ensures the authenticity of the content, verifies the identity of the user who recorded it, and provides a timestamp for the recording."
    // },
    // {
    //     question: "How does our product use blockchain technology?",
    //     answer: "Our product uses blockchain technology to create an immutable record of digital content. It generates additional metadata files that help verify the content against the blockchain records. This ensures the integrity and authenticity of the content."
    // },
    // {
    //     question: "What is a Neotrust ID and why is it important?",
    //     answer: "A Neotrust ID is a unique identifier generated for each user after their identity has been verified. It's important because it links the user's identity to their activities within the system, ensuring accountability and trust."
    // },
    // {
    //     question: "How does our product protect user privacy?",
    //     answer: "Our product is designed with privacy in mind. While we verify the identity of users, this information is not directly linked to the digital content they record. Instead, we use the Neotrust ID, a unique identifier, to maintain user privacy."
    // },
    {
        question: "What is Neotrust NFX?",
        answer: "Neotrust NFX is a system that verifies and records the data integrity and identity of digital content using Non-Fungible Signatures."
    },
    {
        question: "How is NFX different from traditional NFTs",
        answer: "NFX focuses on data integrity, identity verification, and content scalability, whereas traditional NFTs mainly focus on ownership and uniqueness."
    },
    {
        question: "Who can use Neotrust NFX?",
        answer: "Neotrust NFX is designed for individual consumers, content creators, businesses, and large enterprises."
    },
    {
        question: "What are the pricing plans for NFX?",
        answer: "There are three-tiered pricing options: Basic Plan at $4.99/month, Premium Plan at $9.99/month, and a customizable Enterprise Solution."
    },
    {
        question: "How does NFX ensure data security?",
        answer: "NFX utilizes signature files and blockchain technology to provide a high level of security for your digital content."
    },
    {
        question: "Can I customize Neotrust NFX for my specific needs?",
        answer: "Yes, NFX offers customization options to tailor the service to your specific requirements."
    },
    {
        question: "Is Neotrust NFX scalable?",
        answer: "Absolutely, NFX is designed to adapt to different sizes and types of digital content, ensuring scalability."
    },
    {
        question: "What types of digital content can I verify with NFX?",
        answer: "NFX can verify and record various forms of digital content, from documents and images to videos and more."
    },
    {
        question: "How do I get started with Neotrust NFX?",
        answer: "You can choose a suitable pricing plan and sign up through our website."
    },
    {
        question: "Is there a trial version of NFX available?",
        answer: "Currently, Neotrust NFX offers no trial version (this may vary based on company policy)."
    },
    {
        question: "Is my digital content accessible to others on the NFX system?",
        answer: "No, your content remains private and is accessible only to authorized individuals."
    },
    {
        question: "How is NFX’s timestamping feature beneficial?",
        answer: "Timestamping ensures that the digital content’s existence and integrity are recorded at a specific date and time, providing proof of originality."
    },
    {
        question: "What industries can benefit from NFX?",
        answer: "NFX is suitable for various industries, including media, legal, supply chain, and more."
    },
    {
        question: "What support is available for NFX users?",
        answer: "Neotrust NFX offers customer support through email, live chat, and phone."
    },
    {
        question: "Can I cancel my NFX subscription?",
        answer: "Yes, you can cancel your subscription based on the terms and conditions stated in the service agreement."
    },
    {
        question: "Is Neotrust NFX compatible with other blockchain technologies?",
        answer: "NFX is designed to work with blockchain technology, but specific compatibility details should be confirmed with our support team."
    },
    {
        question: "How does NFX help in content creation?",
        answer: "NFX allows content creators to verify the authenticity of their work, providing assurance of originality."
    },
    {
        question: "What are meta-data files in NFX?",
        answer: "These are files associated with digital content, including a manifest file, signature file, and signature block file, facilitating verification."
    },
    {
        question: "Can I integrate NFX with my existing systems?",
        answer: "Integration capabilities may vary, and it’s best to consult with our technical support for specific details."
    },
    {
        question: "How do I upgrade my NFX plan?",
        answer: "Upgrading can be done through your user account on our website or by contacting our support team."
    },
    {
        question: "Is Neotrust NFX compliant with legal regulations?",
        answer: "NFX aims to align with industry compliance standards, but it is advisable to consult with legal experts in your jurisdiction."
    },
    {
        question: "What browsers and devices support NFX?",
        answer: "NFX is designed to be compatible with major browsers and devices; please check our website for specific details."
    },
    {
        question: "How can I provide feedback on NFX?",
        answer: "Feedback can be submitted through our website’s contact form or directly through our support channels."
    },
    {
        question: "Is there a community or forum for NFX users?",
        answer: "Yes, you can join our community through our website, where you can engage with other users and our team."
    },
    {
        question: "What is the future roadmap for Neotrust NFX?",
        answer: "The future roadmap includes continuous improvements, new features, and expanded integrations, reflecting our commitment to innovation and excellence."
    },
    // {
    //     question: "",
    //     answer: ""
    // },
];

const FAQsPage: React.FC = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null); // Collapse if already expanded
        } else {
            setExpandedIndex(index); // Expand if not expanded
        }
    };

    return (
        <div className="dark">
            <div className="px-4 py-20 bg-offwhite">
                <div className="flex flex-col max-w-7xl mx-auto md:flex-row">
                    <h2 className="w-full mr-8 text-3xl font-extrabold leading-9 md:w-1/3">
                        Frequently-asked questions
                    </h2>
                    <dl className="w-full md:w-2/3">
                        {faqs.map((item, index) => (
                            <div key={index} className="faq-item border-t p-4">
                                <dt
                                    className={`mb-2 cursor-pointer flex justify-between items-center ${
                                        expandedIndex === index ? 'active' : ''
                                    }`}
                                    onClick={() => handleToggle(index)}
                                >
                                    <h3 className="text-xl font-semibold">{item.question}</h3>
                                    <span className="toggle-icon text-gray-500">{expandedIndex === index ? '▲' : '▼'}</span>
                                </dt>
                                {expandedIndex === index && (
                                    <dd className="mb-2">
                                        <p>{item.answer}</p>
                                    </dd>
                                )}
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default FAQsPage;
