import React, { useState } from 'react';
import { UserProfileAddress } from '../../utils/types';

interface IProps {
	onNextStep: () => void; // Callback for the next step
	initValue: UserProfileAddress;
	handleUserProfileAddress: (profileAddress: UserProfileAddress) => void | Promise<void>;

}

const ProfileDetailsForm: React.FC<IProps> = ({ onNextStep, handleUserProfileAddress, initValue }) => {
	const [formData, setFormData] = useState<UserProfileAddress>(initValue);

	const [errors, setErrors] = useState<Record<string, string>>({
		fullName: '',
		// lastName: '',
		addressLine1: '',
		city: '',
		state: '',
		country: '',
		zipCode: '',
	});

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();

		const validationErrors = validateFormData();
		setErrors(validationErrors);
		if (Object.keys(validationErrors).length === 0) {
			handleUserProfileAddress(formData);
			onNextStep();
		}
	};

	const validateFormData = () => {
		const validationErrors: Record<string, string> = {};

		if (formData.fullName.trim() === '') {
			validationErrors.fullName = 'First name is required.';
		}
		// if (formData.lastName.trim() === '') {
		// 	validationErrors.lastName = 'Last name is required.';
		// }
		if (formData.addressLine1.trim() === '') {
			validationErrors.addressLine1 = 'Address Line 1 is required.';
		}
		if (formData.city.trim() === '') {
			validationErrors.city = 'City is required.';
		}
		if (formData.state.trim() === '') {
			validationErrors.state = 'State is required.';
		}
		if (formData.country.trim() === '') {
			validationErrors.country = 'Country is required.';
		}
		if (formData.zipCode.trim() === '') {
			validationErrors.zipCode = 'ZIP / Postal code is required.';
		}
		return validationErrors;
	};

	return (
		<form onSubmit={handleSubmit} className="p-12 m-4 mx-auto flex flex-col min-w-[50%] max-w-7xl items-center shadow sm:rounded-lg bg-white">
			<div className="border-b pb-6 border-bluegray-900/10 w-full">
				<h2 className="text-base font-semibold leading-7 text-bluegray-900">Personal Information</h2>
				{/* <p className="mt-1 text-sm leading-6 text-gray-600">
					Use a permanent address where you can receive mail.
				</p> */}

				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
					<div className="sm:col-span-6">
						<label htmlFor="full-name" className="block text-sm font-medium leading-6 text-bluegray-900">
							Full Name
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="fullName"
								id="full-name"
								autoComplete="given-name"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.fullName ? 'border-red-500' : ''
									}`}
								value={formData.fullName}
								onChange={handleInputChange}
							/>
							{errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
						</div>
					</div>

					{/* <div className="sm:col-span-6">
						<label htmlFor="last-name" className="block text-sm font-medium leading-6 text-bluegray-900">
							Last name
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="lastName"
								id="last-name"
								autoComplete="family-name"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.lastName ? 'border-red-500' : ''
									}`}
								value={formData.lastName}
								onChange={handleInputChange}
							/>
							{errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
						</div>
					</div> */}

					<div className="col-span-full">
						<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-bluegray-900">
							Address Line 1
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="addressLine1"
								id="street-address"
								autoComplete="street-address"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.addressLine1 ? 'border-red-500' : ''
									}`}
								value={formData.addressLine1}
								onChange={handleInputChange}
							/>
							{errors.addressLine1 && <p className="text-red-500 text-sm">{errors.addressLine1}</p>}
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="street-address2" className="block text-sm font-medium leading-6 text-bluegray-900">
							Address Line 2
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="addressLine2"
								id="street-address2"
								autoComplete="street-address2"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.addressLine1 ? 'border-red-500' : ''
									}`}
								value={formData.addressLine2}
								onChange={handleInputChange}
							/>
						</div>
					</div>

					<div className="sm:col-span-4 sm:col-start-1">
						<label htmlFor="city" className="block text-sm font-medium leading-6 text-bluegray-900">
							City
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="city"
								id="city"
								autoComplete="address-level2"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.city ? 'border-red-500' : ''
									}`}
								value={formData.city}
								onChange={handleInputChange}
							/>
							{errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
						</div>
					</div>

					<div className="sm:col-span-4">
						<label htmlFor="region" className="block text-sm font-medium leading-6 text-bluegray-900">
							State / Province
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="state"
								id="region"
								autoComplete="address-level1"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.state ? 'border-red-500' : ''
									}`}
								value={formData.state}
								onChange={handleInputChange}
							/>
							{errors.state && <p className="text-red-500 text-sm">{errors.state}</p>}
						</div>
					</div>

					<div className="sm:col-span-4">
						<label htmlFor="country" className="block text-sm font-medium leading-6 text-bluegray-900">
							Country
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="country"
								id="country"
								autoComplete="country-name"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:max-w-xs sm:text-sm sm:leading-6 ${errors.country ? 'border-red-500' : ''
									}`}
								value={formData.country}
								onChange={handleInputChange}
							/>
							{errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
						</div>
					</div>

					<div className="sm:col-span-6">
						<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-bluegray-900">
							ZIP / Postal code
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="zipCode"
								id="postal-code"
								autoComplete="postal-code"
								className={`block w-full rounded-md border-0 py-1.5 text-bluegray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ntpurple sm:text-sm sm:leading-6 ${errors.zipCode ? 'border-red-500' : ''
									}`}
								value={formData.zipCode}
								onChange={handleInputChange}
							/>
							{errors.zipCode && <p className="text-red-500 text-sm">{errors.zipCode}</p>}
						</div>
					</div>

				</div>
			</div>

			<div className="mt-6 mx-auto flex items-center max-w-7xl justify-center gap-x-6">
				<button
					type="submit"
					className="rounded-full bg-ntpurple px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ntpurple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple"
				>
					Save
				</button>
			</div>
		</form>
	);
};

export default ProfileDetailsForm;
