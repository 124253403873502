import { CheckIcon } from '@heroicons/react/24/solid';

interface StepsProps {
  steps: {
    id: string;
    name: string;
    href ?: string;
    status: string
    // status: 'complete' | 'current' | 'upcoming';
  }[];
}

const Steps: React.FC<StepsProps> = ({ steps }) => {
  return (
    <nav aria-label="Progress" className='flex flex-col items-center'>
      <ol role="list" className="max-w-7xl divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-ntpurple group-hover:bg-ntpurple-500">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-bluegray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a href={step.href} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-ntpurple">
                  <span className="text-ntpurple">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-ntpurple">{step.name}</span>
              </a>
            ) : (
              <span className="flex items-center px-6 py-4 text-sm font-medium text-gray-400">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                  <span className="text-gray-500">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-400">{step.name}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Steps;
