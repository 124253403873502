import React from 'react';
import HeroSection from './HeroSection';
import TrustFeaturesSection from './TrustFeaturesSection';
import TrustIndustriesSection from './TrustIndustriesSection';
import WhyNeotrustSection from '../../components/common/WhyNeotrustSection';

const TrustPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeroSection />
            <TrustFeaturesSection />
            <TrustIndustriesSection />
            <WhyNeotrustSection />
        </div>
    );
};

export default TrustPage;
